<div class="container-fluied instruction-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 clsNeedHelptxt m-3">
                <!-- <h2 class="topHeading">Hello, Lets Be In touch!!</h2> -->
            </div> 
        </div>  
        <div class="row contactWrapper border shadow">     
            <!-- <div class="col-md-8 contForm p-3">
                <h5 class="contFormTitle">Send Us a Message!</h5>
                <div class="contFormCtrl">           

                        
                        <div class=" mb-5">
                            <form [formGroup]= "contactForm">
                
                               
                                <div class="row">
                
                                   
                                    <div class="col-md-6">
                                        <div class="mb-0">
                                            <input type="text" id="name" name="name" class="form-control" 
                                            formControlName="contName" placeholder="Your name">
                                            <label for="name" class=""></label>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-6">
                                        <div class="mb-0">
                                            <input type="text" id="email" name="email" class="form-control" 
                                            formControlName="contEmail" placeholder="Your email">
                                            <label for="email" class=""></label>
                                        </div>
                                    </div>
                                   
                
                                </div>
                               
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-0">
                                            <input type="text" id="subject" name="subject" class="form-control" 
                                            formControlName="contSub" placeholder="Subject">
                                            <label for="subject" class=""></label>
                                        </div>
                                    </div>
                                </div>                              
                                <div class="row">
                
                                   
                                    <div class="col-md-12">
                
                                        <div class="md-form">
                                            <textarea type="text" id="message" name="message" rows="2" class="form-control"
                                            formControlName="contMatter" placeholder="Your message"></textarea>
                                            <label for="message"></label>
                                        </div>
                
                                    </div>
                                </div>
                           
                            </form>
                
                            <div class="text-center text-md-left">
                                <a class="btn btn-primary" (click)="onsubmit()">Send</a>
                            </div>
                            <div class="status"></div>
                        </div>        
                    </div>
            </div> -->
            <div class="col-md-8 contInfo mt-3">
                <h5 class="py-2 underline">Contact Information</h5>
                <div class="contDetails">
                    <h5 class="headingName" ><i class="bi bi-telephone-fill "></i>Phone No.</h5>
                    <p class="headingValue">+91 (832) 241 7593 (GBSHSE)    </p>
                    <p class="headingValue">+91 (832) 241 3085 (Secretary)</p>
                    <p class="headingValue">+91 (832) 241 7584 (Chairman)</p>
                </div>
                <div class="contDetails">
                    <h5 class="headingName my-2"><i class="bi bi-at mr-2"></i>Email Address</h5>
                    <p class="headingValue">sec-gbshse.goa@nic.in (Secretary)</p>
                    <p class="headingValue">chairman-gbshse.goa@nic.in (Chairman)</p>
                </div>
                <div class="contDetails mb-2" >
                    <h5 class="headingName my-2"><i class="bi bi-geo-alt-fill mr-2"></i>Our Office</h5>
                    <p class="headingValue">Goa Board of Secondary and Higher Secondary Education Alto Betim, Porvorim Goa 403521</p>
                </div>
                <br> <br> <br>
            </div>
            <br>
        </div>

    </div>
            
</div>