<div class="container login-container bg-light mb-3">
    <br>
    <div class="row mb-3 justify-content-md-center">
         
        <div class="col-md-8">
            <div class="card">
                <h5 class="card-header cardheader"><i class="bi bi-lock-fill"></i>Forgot Password</h5>
                <div class="card-body">
                  
                    <div class="hsscdiv" >
  
                      <form [formGroup]="forgotpwdform" >
                        
                        <div class="form-group row">
                          <label for="staticEmail" class="col-sm-4 col-form-label">Enter Registered Email</label>
                          <div class="form-group col-sm-8 my-2">
                            <input type="email" class="form-control" formControlName="email"  value="" [ngClass]="{ 'is-invalid': submitted && personal.email.errors }">
                            <div *ngIf="submitted && personal.email.errors" class="invalid-feedback">
                             <div *ngIf="personal.email.errors.required">
                               Email Id Is Required.
                             </div>
                             <div *ngIf="personal.email.errors.email">Email must be a valid email address</div>
                           </div>
                        </div>
                        </div>
                        <!-- <div class="form-group row">
                          <label for="inputPassword" class="col-sm-4 col-form-label">New Password</label>
                          <div class="form-group col-sm-8 my-2">
                            <input type="password" class="form-control" formControlName="newpassword" placeholder="Your Password *" value="" />
                        </div>
                        </div> -->
                        <!-- <div class="row">
                            <label for="inputPassword" class="col-sm-4 col-form-label">Security Pin</label>
                            
                            <span class="col-sm-6 captcha" id="captcha" style="background-image:url('assets/img/brand/dot.jpg');background-repeat: no-repeat;width:'180px'">{{captcha}} </span>
                            <span class="col-sm-2"> <a (click)="refresh()"> <img src="assets/img/brand/refresh.png" height="50px" width="50px"></a></span>
                           
                        </div>
                        <div class="form-group row">
                                <label for="inputPassword" class="col-sm-4 col-form-label">Enter Security Pin</label>
                                <div class="form-group col-sm-8 my-2">
                                    <input type="text" class="form-control" formControlName="captcha_text" placeholder="Enter Above Captcha" value="" />
                                </div>
                               </div> -->
                            <div class="form-group text-center">
                                <input type="button" class="btnSubmit btn btn-primary" value="Submit" (click)="onforgotpwdsubmit()" />
                                
                            </div>
                            
                      </form>
                     
                    </div>
                    
                    
                </div>
              </div>
        </div>
       
    </div>
  
    <div class="row mb-3"></div>
  </div>