<section class=" m-5 p-5">
  <div class="row  ">
    
<div class="col-md-8 mx-auto  border s br f ">
  <h4 class="text-center bc rh text-white p-2 mb-5"><i class="bi bi-calendar2-fill"></i> Change Last Date Of Submission</h4>
  <div class="row">

          <div class="col-md-8 mx-auto border my-4 py-5">
                   
              <form class="row g-3 justify-content-center from-inline my-4">
                  <div class="col-auto">
                    <div class="row">
                  <div class="col-md-6">
                    <label class=" ">Select Standard:</label></div>
                    <div class="col-md-6">
                    <select class="form-select p-1 px-5 " aria-label="Default select example">
                      <option selected>--Select--</option>
                      <option value="9th">9th</option>
                      <option value="10th">10th</option>  
                    </select>
                    </div>
                    </div>
                    </div>
                  
               </form>
               <form class="row g-3 justify-content-center ">
                  <div class="col-auto">
                    <div class="row">
                      <div class="col-md-6">
                      <label class="ml-5 pr-4 ">Select Last Date:</label>
                      </div>
                      <div class="col-md-6">
                      <input  type="date" class=" p-1 mr-3" >
                    </div>
                    </div>
                  </div>
               </form>
          
          </div>
  </div>
      <div class="text-right">
          <button type="submit" class="btn btn-primary m-5 mr-5 p-2 px-3">Submit</button>
      </div> 
  
</div>
</div>
</section>


