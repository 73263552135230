<div class="container login-container bg-light mb-3">
    <br>
    <div class="card ">
        <!-- <span>
          <h5 style="text-align: center;margin-top: 10px;">Kindly Change your password to proceed to complete your profile</h5>
         
        </span> -->
        <div class="row g-0">
           <div class="col-md-6">
            <img src="assets/img/brand/edu.jpg" class="img-fluid rounded-start img" alt="...">
          </div>
          <div class="col-md-6">
            <div class="card-body">
              <h5 class="card-title"></h5>
              
                <div *ngIf = "std=='9th'; else eleventhlastdate">
                  <h5 class="card-text">  <b>Last Date For Application Submission - {{lastdatestud_9th}}</b></h5>
                </div>
                <ng-template #eleventhlastdate>
                  <h5 class="card-text">  <b>Last Date For Application Submission - {{lastdatestud_11th}}</b></h5>
                </ng-template>
                <div *ngIf="std =='9th' else elevnth">
                  <div *ngIf=" submission_status == 'New' || submission_status == 'Pending' else status">
                    <h5 class="card-text">
                      <b>Application Status : {{submission_status}}</b>
                    </h5>
                    <button class="btn btn-primary" [routerLink]="['/studentchklanding']" >View Your Application</button>
                  </div>
                  <ng-template #status>
                    <h5 class="card-text">
                      <b>Application Status : {{submission_status}}</b>
                    </h5>
                    
                      <button class="btn btn-primary" [routerLink]="['/studentchklanding']" >View Your Application</button>
                   
                   </ng-template>
                </div>
                <ng-template #elevnth>
                  <div *ngIf=" submission_status == 'New' || submission_status == 'Pending' else elvstatus">
                
               
                    <h5 class="card-text">
                      <b>Application Status : {{submission_status}}</b>
                    </h5>
                    <button class="btn btn-primary" [routerLink]="['/elevenstudents']" >View Your Application</button>
                  </div>
                  <ng-template #elvstatus>
                    <h5 class="card-text">
                      <b>Application Status : {{submission_status}}</b>
                    </h5>
                    <div *ngIf = "std=='11th'">
                      <button class="btn btn-primary" [routerLink]="['/elevenstudents']" >View Your Application</button>
                    </div>
                   </ng-template>
                </ng-template>
              
          </div>
          <br>
        </div>
      </div>
      <div class="row mb-3"></div>
</div>



