<div class="container login-container bg-light mb-3">
    <br>
    <div class="card border-dark mb-3">
        <h5 class="card-header"><i class="bi bi-calendar2-check"></i>&nbsp;Set Exceptional Date</h5>
        <div class="card-body text-dark ">
          
          <form role="form" [formGroup]= "expDateResetform">
            <div  class="justify-content-md-center">           
              <div class="row mb-3">
                  <div class="col-md-1"></div>
                  <div class="col-md-2">
                    <label for="schoolCode" class="col-form-label">School code (SCODE):</label>
                  </div>
                  <div class="col-md-2">
                    <input type="text" (change)="getSchoolDetails()" formControlName="schoolCode" class="form-control" 
                    [value]="scode" id="schoolCode" [ngClass]="{ 'is-invalid': submitted && personal.schoolCode.errors }">
                      <div *ngIf="submitted && personal.schoolCode.errors" class="invalid-feedback">
                        <div *ngIf="personal.schoolCode.errors.required">
                            Code Is Required.
                        </div>
                      </div>
                  </div>
                  <div class="col-md-2 text-right">
                    <label for="schoolName" class="col-form-label">School Name:</label>
                  </div>
                  <div class="col-md-4">
                    <input type="text" [attr.disabled]="true" formControlName="schoolName" class="form-control" 
                    [value]="schoolName" id="schoolName" [ngClass]="{ 'is-invalid': submitted && personal.schoolName.errors }">
                   </div>                
              </div>
              <div class="row mb-3">
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <label for="schoolMob" class="col-form-label">School Contact No:</label>
                </div>
                <div class="col-md-2">
                  <input type="text" [attr.disabled]="true" formControlName="schoolMob" class="form-control"
                  [value]="schoolMob" id="schoolMob" [ngClass]="{ 'is-invalid': submitted && personal.schoolMob.errors }">
                    <div *ngIf="submitted && personal.schoolMob.errors" class="invalid-feedback">
                      <div *ngIf="personal.schoolMob.errors.required">
                          Code Is Required.
                      </div>
                    </div>
                </div>
                <div class="col-md-2 text-right">
                  <label for="schoolAdd" class="col-form-label">School Address:</label>
                </div>
                <div class="col-md-4">
                  <input type="text" [attr.disabled]="true" formControlName="schoolAdd" class="form-control" 
                  [value]="schoolAdd" id="schoolAdd" [ngClass]="{ 'is-invalid': submitted && personal.schoolAdd.errors }">
                </div>              
            </div>
            <div class="row mb-3">
              <label  class="col-form-label" class="col-md-5 text-right"><b>Curent Exceptional date:</b></label>
              <div class="col-sm-3">{{exceptionaldate}} </div>
              <!-- <div class="currentLastDate col-sm-6" *ngIf="StandardValueStud=='11th'">{{exceptionaldate}} </div> -->
            </div>
            <div class="row mb-3">
                <div class="col-md-1"></div>
                <div class="col-md-4 text-right">
                  <label for="newExpDate" class="col-form-label">Exceptional Date:</label>
                </div>
                <div class="col-md-4">
                  <input type="date" formControlName="newExpDate" class="form-control" id="newExpDate" [ngClass]="{ 'is-invalid': submitted && personal.newExpDate.errors }">
                    <div *ngIf="submitted && personal.newExpDate.errors" class="invalid-feedback">
                      <div *ngIf="personal.newExpDate.errors.required">
                          Date Is Required.
                      </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-2 mt-5">
              <div class="col-md-0">
                <div class="form-group" style="text-align: center;">
                  <input type="button" class="btnSubmit btn btn-primary"  value="Save" (click)=" onsubmit()" /> &nbsp;
                  <input type="button" class="btnSubmit btn btn-secondary"  value="Cancel" (click)="resetExpDateResetform()" /> &nbsp;
                </div>
              </div>
            </div>
            </div>
          </form>              
        </div>
    </div>   
</div>
  