<div class="container login-container bg-light mb-3">
    <br>
    <div class="card border-dark mb-3">
        <h5 class="card-header">Create Board User</h5>
        <div class="card-body text-dark ">
            <!-- <figcaption class="blockquote" style="color: #0062cc;">
                <cite title="Source Title"><b>- Personal Information</b></cite>
             </figcaption> <hr> -->
             <form role="form" [formGroup]= "boarduserform">
                 <div  class="justify-content-md-center">
                
                    <div class="form-group row mb-3">
                        
                        <label for="staticname" class="col-sm-2 col-form-label">Employee Id</label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="employee_id" class="form-control" id="staticName" [ngClass]="{ 'is-invalid': submitted && personal.employee_id.errors }">
                          <div *ngIf="submitted && personal.employee_id.errors" class="invalid-feedback">
                            <div *ngIf="personal.employee_id.errors.required">
                              User's ID Is Required.
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticfathername" class="col-sm-2 col-form-label">User Full Name</label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="board_user_fname" class="form-control" id="inputboard_user_fname" [ngClass]="{ 'is-invalid': submitted && personal.board_user_fname.errors }">
                           <div *ngIf="submitted && personal.board_user_fname.errors" class="invalid-feedback">
                             <div *ngIf="personal.board_user_fname.errors.required">
                              Full Name Is Required.
                             </div>
                           </div>
                       
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticmothername" class="col-sm-2 col-form-label">Username</label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="username" class="form-control" id="inputusername"  [ngClass]="{ 'is-invalid': submitted && personal.username.errors }">
                           <div *ngIf="submitted && personal.username.errors" class="invalid-feedback">
                             <div *ngIf="personal.username.errors.required">
                               Username Is Required.
                             </div>
                           </div>
                 
                        </div>
                      </div>
                      <!-- <div class="form-group row mb-3">
                        <label for="staticdob" class="col-sm-2 col-form-label">Password</label>
                        <div class="col-sm-5">
                          <input type="password" formControlName="password" class="form-control" id="inputpassword" [ngClass]="{ 'is-invalid': submitted && personal.password.errors }">
                          <div *ngIf="submitted && personal.password.errors" class="invalid-feedback">
                            <div *ngIf="personal.password.errors.required">
                                Password Is Required.
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div class="form-group row mb-3">
                       
                          <!-- <input type="button" class="btnSubmit btn btn-success"  value="Save Changes" (click)="savedata()" /> &nbsp; -->
                          <input type="button" class="btnSubmit btn btn-primary"  value="Submit" (click)="onsubmit()" /> 
                          <!-- (click)="onstudentprofilesubmit(studentprofileform.value)" -->
                       </div>
                      
                    </div>
                  
               
              </form>
              
        </div>
      </div>
      <div class="row mb-3"></div>
</div>