<div class=" m-2 p-2 NotiRemWrapper container-fluid">
  <div class="row">
       <div class="col-md-8 mx-auto br border s f">            
          <h4 class="text-center bc rh text-white p-2"><i class="bi bi-bell-fill"></i> Configure Notifications & Reminders & Messages</h4>
          <ul class="nav nav-pills justify-content-center">
              <li class="nav-item  mx-3 pt-4 ">
                <a class="nav-link active p-2 px-2 " data-toggle="pill" href="#noti">Notifications</a>
              </li>
              <li class="nav-item  mx-3 pt-4">
                <a class="nav-link p-2 px-2" data-toggle="pill" href="#rem">Reminders</a>
              </li>
              <li class="nav-item  mx-3 pt-4">
                <a class="nav-link p-2 px-2" data-toggle="pill" href="#msg">Messages</a>
              </li>
            </ul>
            <div class="tabLook"></div>
            
            <div class="tab-content">                
              <div id="noti" class="tab-pane  active">
                <form role="form" [formGroup]= "notiConfigform">
                  <div class="row">
                    <div class="selectAudi justify-content-center mt-1 pt-2 row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-4 form-check form-check-inline">
                        <input type="radio" name="targetAudiNoti" value="schoolNoti" id="schoolNoti"
                        class="form-check-input" formControlName="targetAudiNoti">
                        <label class="form-check-label" for="schoolNoti"> For School</label>
                      </div>                      
                      <div class="col-sm-4 form-check form-check-inline">
                        <input type="radio" name="targetAudiNoti" value="studNoti" id="studNoti"
                        class="form-check-input" formControlName="targetAudiNoti">
                        <label class="form-check-label" for="studNoti">For Student</label>  
                      </div>    
                      <!-- all noti Icon -->
                      <div class="col-sm-2 text-right pr-5">
                        <a *ngIf="!notificationCont.targetAudiNoti.invalid" href="#" data-toggle="modal" data-target="#allnotifications" title="View All Notifications"><i (click)="showAllNotifications()" class="bi bi-chat-square-dots"></i></a></div>
                    </div>
                      <div class="col-md-10 mx-auto">
                        
                          <div class="form-group">
                              <label class="p-2">Enter Notifications Title</label>
                             <!---<input class="" type="text" placeholder="message here..">-->
                              <input type="text" class="form-control" formControlName="notificatontitle" placeholder="Notification Title here..."
                              [ngClass]="{ 'is-invalid': Notisubmitted && notificationCont.notificatontitle.errors }"
                              >
                              <div *ngIf="Notisubmitted && notificationCont.notificatontitle.errors" class="invalid-feedback">
                                <div *ngIf="notificationCont.notificatontitle.errors.required">
                                  Notification Title is Required!
                                </div>
                              </div>
                              <label for="comment" class="p-2">Enter Notifications here..</label>
                             <!---<input class="" type="text" placeholder="message here..">-->
                              <textarea class="form-control" formControlName="notificatonTxt" rows="4" placeholder="Notification here..."
                              [ngClass]="{ 'is-invalid': Notisubmitted && notificationCont.notificatonTxt.errors }"
                              ></textarea>
                              <div *ngIf="Notisubmitted && notificationCont.notificatonTxt.errors" class="invalid-feedback">
                                <div *ngIf="notificationCont.notificatonTxt.errors.required">
                                  Notification is Required!
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="text-center my-3">
                    <input type="button" class="btnSubmit btn btn-primary"  value="Save" (click)="saveNotification()" /> &nbsp;
                    <input type="button" class="btnSubmit btn btn-secondary"  value="Cancel" (click)="notificationReset()" /> &nbsp;
                  </div>  
                </form>
              </div>
              <div id="rem" class="tab-pane fade">
                <form role="form" [formGroup]= "reminderConfigform">
                  <div class="row"> 
                    <div class="selectAudi justify-content-center mt-1 pt-2 row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-4 form-check form-check-inline">
                        <input type="radio" name="targetAudiRem" value="schoolRem" id="schoolRem"
                        class="form-check-input" formControlName="targetAudiRem">
                        <label class="form-check-label" for="schoolRem"> For School</label>
                      </div>                      
                      <div class="col-sm-4 form-check form-check-inline">
                        <input type="radio" name="targetAudiRem" value="studRem" id="studRem"
                        class="form-check-input" formControlName="targetAudiRem">
                        <label class="form-check-label" for="studRem">For Student</label>  
                      </div>    
                      <div class="col-sm-2 text-right pr-5">
                        <!-- all rem Icon -->
                      <a *ngIf="!reminderCont.targetAudiRem.invalid" href="#" data-toggle="modal" data-target="#allReminders" title="View All Reminders">
                        <i (click)="showAllReminders()" class="bi bi-chat-square-dots"></i></a>               
                      </div>                
                    </div>                  
                      <div class="col-md-10 mx-auto">                        
                          <div class="form-group">
                              <label class="p-2">Enter Reminder Title</label>
                             <!---<input class="" type="text" placeholder="message here..">-->
                              <input type="text" class="form-control" formControlName="remindertitle" placeholder="Reminder title Title here..."
                              [ngClass]="{ 'is-invalid': Reminsubmitted && reminderCont.remindertitle.errors }"
                              >
                              <div *ngIf="Reminsubmitted && reminderCont.remindertitle.errors" class="invalid-feedback">
                                <div *ngIf="reminderCont.remindertitle.errors.required">
                                  Reminder Title is Required!
                                </div>
                              </div>
                              <label for="comment" class="p-2"> Enter Reminders here..</label>
                             <!---<input class="" type="text" placeholder="message here..">-->
                              <textarea class="form-control" formControlName="reminderTxt" rows="4" placeholder="Reminder  here..." 
                              [ngClass]="{ 'is-invalid': Reminsubmitted && reminderCont.reminderTxt.errors }"></textarea>
                              <div *ngIf="Reminsubmitted && reminderCont.reminderTxt.errors" class="invalid-feedback">
                                <div *ngIf="reminderCont.reminderTxt.errors.required">
                                  Reminder Is Required!
                                </div>
                              </div>
                          </div>                    
                      </div>                    
                  </div>
                      <div class="text-center  my-3">
                        <input type="button" class="btnSubmit btn btn-primary"  value="Save" (click)="saveReminder()" /> &nbsp;
                        <input type="button" class="btnSubmit btn btn-secondary"  value="Cancel" (click)="reminderReset()" /> &nbsp;                    
                      </div>  
                    </form>
              </div>
              <div id="msg" class="tab-pane">
                <form role="form" [formGroup]= "MsgToAllform">
                  <div class="row">
                    <div class="selectAudi justify-content-center mt-1 pt-2 row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-4"></div>                      
                      <div class="col-sm-4"></div>    
                      <div class="col-sm-2 text-right pr-5">
                        <!-- all rem Icon -->
                      <a href="#" data-toggle="modal" data-target="#messages" title="View All Messages">
                        <i (click)="showAllMessages()" class="bi bi-chat-square-dots"></i></a>               
                      </div>                
                    </div> 
                      <div class="col-md-10 mx-auto mt-1 pt-2">
                        
                          <div class="form-group">
                              <label class="p-2">Enter Message Title</label>                               
                              <input type="text" class="form-control" formControlName="msgtitle" placeholder="Message Title here..."
                              [ngClass]="{ 'is-invalid': msgSubmitted && msgCont.msgtitle.errors }"
                              >
                              <div *ngIf="msgSubmitted && msgCont.msgtitle.errors" class="invalid-feedback">
                                <div *ngIf="msgCont.msgtitle.errors.required">
                                  Message Title is Required!
                                </div>
                              </div>
                              <label for="comment" class="p-2">Enter Message</label>
                             <!---<input class="" type="text" placeholder="message here..">-->
                              <textarea class="form-control" formControlName="msgTxt" rows="5" placeholder="Message here..."
                              [ngClass]="{ 'is-invalid': msgSubmitted && msgCont.msgTxt.errors }"></textarea>
                              <div *ngIf="msgSubmitted && msgCont.msgTxt.errors" class="invalid-feedback">
                                <div *ngIf="msgCont.msgTxt.errors.required">
                                  Message Is Required!
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="text-center  my-3">
                    <input type="button" class="btnSubmit btn btn-primary"  value="Save" (click)="saveMessage()" /> &nbsp;
                    <input type="button" class="btnSubmit btn btn-secondary"  value="Cancel" (click)="msgReset()" /> &nbsp;
                  </div>  
                </form>
              </div>
            </div>

</div> 
</div> 
<div class="container">
<!--Notification Modal -->
<div class="modal fade justify-content-center" id="allnotifications" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{target_audience}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="notificationWraper border" *ngFor="let item of AllNoticArray; let i = index">
            <div class="notificationTitle">{{i+1}}</div>
            <div class="notiDateTime"> {{item.date|date:'d/M/y'}} Time: {{item.date|date:'shortTime'}}</div>  
            <div class="notificationTitle">{{item.title}}</div>
            <div class="notificationTxt">{{item.message}}</div>            
          </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div><!--  end of notif model -->
<!--Reminder Modal -->
<div class="modal fade justify-content-center" id="allReminders" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{target_audience}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="notificationWraper border" *ngFor="let item of AllNoticArray; let i = index">
            <div class="notificationTitle">{{i+1}}</div>
            <div class="notiDateTime"> {{item.date|date:'d/M/y'}} Time: {{item.date|date:'shortTime'}}</div>
            <div class="notificationTitle">{{item.title}}</div>
            <div class="notificationTxt">{{item.message}}</div>
            <!-- <div class="notiDateTime" *ngFor="let i of item.date | slice:'':2 ">{{i}}</div> -->
          </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div><!--  end of reminder model -->
<!--Message Modal -->
<div class="modal fade justify-content-center" id="messages" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{target_audience}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="notificationWraper border" *ngFor="let item of AllNoticArray; let i = index">
            <div class="notificationNo">{{i+1}}</div>
            <div class="notiDateTime">{{item.date | date: 'd/M/y'}} Time:{{item.date | date: 'shortTime'}}</div>
            <div class="notificationTitle">{{item.title}}</div>
            <div class="notificationTxt">{{item.message}}</div>           
          </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div> <!-- end of msgModel-->
</div>   <!--end of container for model--> 

</div>

        