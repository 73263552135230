<div class="container main-cont">  
    <div class="paymentScreenWrapper">
      
        <div class="d-flex paymentScreenbg shadow">
            <div class=" sec1">
                <div class="paymentDetailsWrap">
                    <h5 class="headingValue mb-5 underline">Online Fee Payment:</h5>
                    <div class="payableDetails">
                        <p class="headingName"><i class="bi bi-bank2 mr-2"></i>Name of School</p>
                        <h3 class="headingValue">Star City School</h3>
                    </div>
                    <div class="payableDetails">
                        <p class="headingName pt-2"><i class="bi bi-people mr-2"></i>Number of Student</p>
                        <h3 class="headingValue">10</h3>
                    </div>
                    <div class="payableDetails">
                        <p class="headingName pt-2"><i class="bi bi-calculator mr-2"></i>Total Amount Payable</p>
                        <h3 class="headingValue">10,000</h3>
                    </div>
                </div>
            </div>
            
            <div class=" sec2">
              <i [routerLink]="['/candidates-payment']" class="bi bi-x-square-fill btnClose"></i>
                <p class="impNote">NOTE: In case of payment, if respected amount is deducted from account and 
                    payment deduction message has been received then kindly wait for 72 hours
                    before reinitializing the payment process.
                </p>
                <div class="payOptionsWrap">
                     <div class="form-check">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="optradio">Debit Card
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="optradio">Credit Card
                        </label>
                      </div>
                      <div class="form-check disabled">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="optradio" >UPI
                        </label>
                      </div>
                      <div class="form-check disabled">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="optradio" >Internet Banking
                        </label>
                      </div>
                      <div class="clsCkeck">
                        <div class="form-check">
                            <label class="form-check-label">
                            <input type="checkbox" (change)="funChecked($event)" class="form-check-input" name="optradio">I agree to pay fees online.
                            </label>
                        </div>
                      </div>
                      
                      <div class="d-flex flex-row-reverse mt-5">
                        <button type="button" class="btn btn-primary" (click)="Pay()" [disabled]="checkTicked">Pay Online</button>
                      </div>
                </div>
        </div>
    </div>
</div>