<div class="container">
    <div class="dashboardCont">
        <div class="row">
            <div class="col-sm-12">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">Last Dates</h4>
                     <div *ngIf="standard =='9th'; else test"><!--  last dates for 9th -->
                        <div class="card-body">   
                            <div class="lastDateText"> 
                                <h5 >Last Date of Student Registration</h5>
                                <div class="clsLastDate clsBlinkingtxt">{{lastdatestud_9th}}</div>
                            </div>
                            <div class="lastDateText"> 
                                <h5 >Last Date of submission for School</h5>
                                <div class="clsLastDate clsBlinkingtxt">{{lastdate_9th}}</div>
                            </div>
                        </div>
                    </div>
                   
                    <ng-template #test>
                        <div ><!--  last dates for 11th -->
                            <div class="card-body">   
                                <div class="lastDateText"> 
                                    <h5 >Last Date of Student Registration</h5>
                                    <div class="clsLastDate clsBlinkingtxt">{{lastdatestud_11th}}</div>
                                </div>
                                <div class="lastDateText"> 
                                    <h5 >Last Date of submission for School</h5>
                                    <div class="clsLastDate clsBlinkingtxt">{{lastdate_11th}}</div>
                                </div>
                            </div>
                        </div>
                      </ng-template>
                </div>
            </div>  
        </div>
        <div class="row" *ngIf="standard =='9th'; else geteleventh">
            <div class="col-sm-6" >
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{registration9_title}}</h4>
                    <div class="card-body ">
                        <div *ngIf="!regi9StatusGhraphShow" class="chartadjust">
                            <google-chart #chart                
                            [type]="registration9_type"
                            [data]="registration9_data"                
                            [options]="registration9_options"
                            [width]="registration9_width"
                            [height]="registration9_height">                       
                        </google-chart>
                        </div>
                        <div *ngIf="regi9StatusGhraphShow" class="alert alert-danger m-3 py-3" role="alert">
                            <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                            <a class="alert-link">Registration Status Data</a><br> is not available.
                        </div> 
                        <!-- <div class="custome-legend">
                        <span class="left-cont">{{registration9_data[0][0]}} : {{registration9_data[0][1]}}</span>
                        <span class="right-cont">{{registration9_data[1][0]}} : {{registration9_data[1][1]}}</span>
                        </div> -->
                    </div>
                </div>
            </div> 
            <div class="col-sm-6">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{payment_title}}</h4>
                    <div class="card-body">  
                        <div *ngIf="!payStatusGhraphShow" class="chartadjust">
                            <google-chart #chart                
                            [type]="payment_type"
                            [data]="paidunpaid_data"                
                            [options]="payment_options"
                            [width]="payment_width"
                            [height]="payment_height">
                        </google-chart>
                        </div>   
                        <div *ngIf="payStatusGhraphShow" class="alert alert-success m-3 py-3" role="alert">
                            <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                            <a class="alert-link">Payment Status Data</a><br> is not available.
                        </div> 
                        <!-- <div class="custome-legend">
                            <span class="left-cont">{{registration11_data[0][0]}} : {{registration9_data[0][1]}}</span>
                            <span class="right-cont">{{registration11_data[1][0]}} : {{registration9_data[1][1]}}</span>
                        </div> -->
                    </div>
                </div>
            </div> 
           
                    
        </div> 
        <ng-template #geteleventh>
                
        <div class="row">          
           
                <div class="col-sm-6">
                    <div class="card cardCont">
                        <h4 class="clsChartHeading">{{registration11_title}}</h4>
                        <div class="card-body">    
                            <div *ngIf="!regi11StatusGhraphShow" class="chartadjust">
                                <google-chart #chart                
                                [type]="registration11_type"
                                [data]="registration11_data"                
                                [options]="registration11_options"
                                [width]="registration11_width"
                                [height]="registration11_height">
                            </google-chart>
                            </div> 
                            <div *ngIf="regi11StatusGhraphShow" class="alert alert-danger m-3 py-3" role="alert">
                                <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                                <a class="alert-link">Registration Status Data</a><br> is not available.
                            </div>                            
                            <!-- <div class="custome-legend">
                                <span class="left-cont">{{registration11_data[0][0]}} : {{registration9_data[0][1]}}</span>
                                <span class="right-cont">{{registration11_data[1][0]}} : {{registration9_data[1][1]}}</span>
                            </div> -->
                        </div>
                    </div>
                </div> 
                <div class="col-sm-6">
                    <div class="card cardCont">
                        <h4 class="clsChartHeading">{{payment_title}}</h4>
                        <div class="card-body">    
                            <div *ngIf="!payStatusGhraphShow" class="chartadjust">
                                <google-chart #chart                
                                [type]="payment_type"
                                [data]="paidunpaid_data"                
                                [options]="payment_options"
                                [width]="payment_width"
                                [height]="payment_height">
                            </google-chart>
                            </div> 
                            <div *ngIf="payStatusGhraphShow" class="alert alert-success m-3 py-3" role="alert">
                                <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                                <a class="alert-link">Payment Status Data</a><br> is not available.
                            </div> 
                            <!-- <div class="custome-legend">
                                <span class="left-cont">{{registration11_data[0][0]}} : {{registration9_data[0][1]}}</span>
                                <span class="right-cont">{{registration11_data[1][0]}} : {{registration9_data[1][1]}}</span>
                            </div> -->
                        </div>
                    </div>
                </div> 
                    
        </div>
        </ng-template>


        <div class="row">
            <div class="col-sm-6">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{maleFemale_title}}</h4>
                    <div class="card-body">    
                        <div *ngIf="!genStatusGhraphShow" class="chartadjust">
                            <google-chart #chart                
                            [type]="maleFemale_type"
                            [data]="maleFemale_data"                
                            [options]="maleFemale_options"
                            [width]="maleFemale_width"
                            [height]="maleFemale_height">
                        </google-chart>
                        </div>
                        <div *ngIf="genStatusGhraphShow" class="alert alert-warning m-3 py-3" role="alert">
                            <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                            <a class="alert-link">Male /Female Status Data</a><br> is not available.
                        </div>
                        <!-- <div class="custome-legend">
                            <span class="left-cont">{{maleFemale_data[0][0]}} : {{registration9_data[0][1]}}</span>
                            <span class="right-cont">{{maleFemale_data[1][0]}} : {{registration9_data[1][1]}}</span>
                        </div> -->
                    </div>
                </div>
            </div>  
            <div class="col-sm-6">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{studentstatus_title}}</h4>
                    <div class="card-body">    
                        <div *ngIf="!studStatusGhraphShow" class="chartadjust">
                            <google-chart #chart                
                            [type]="studentstaus_type"
                            [data]="studentstaus_data"                
                            [options]="student_options"
                            [width]="studentstatus_width"
                            [height]="studentstatus_height">
                        </google-chart>
                        </div> 
                        <div *ngIf="studStatusGhraphShow" class="alert alert-secondary m-3 py-3" role="alert">
                            <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                            <a class="alert-link">Student Status Data</a><br> is not available.
                        </div>                       
                        <!-- <div class="custome-legend">
                            <span class="left-cont">{{maleFemale_data[0][0]}} : {{registration9_data[0][1]}}</span>
                            <span class="right-cont">{{maleFemale_data[1][0]}} : {{registration9_data[1][1]}}</span>
                        </div> -->
                    </div>
                </div>
            </div> 
        </div>             
        
    </div>
    </div>    