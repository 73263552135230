<div class="container login-container bg-light mb-3">
    <br>
    <div class="row mb-3 justify-content-md-center">
         
        <div class="col-md-12">
            <div class="card">
                <h5 class="card-header cardheader"><i class="bi bi-border-width"></i> View Schools Details</h5>
                <div class="card-body">
                  <div class="hsscdiv" >
                       <table class="table  table-bordered">
                            <thead>
                                <tr>
                                    <th>School Code</th>
                                    <th>{{schoolid}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>School Name</td>
                                    <td>{{schoolName}}</td>
                                </tr>
                                <tr>
                                    <td>Principal Name</td>
                                    <td>{{principal}}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{{schoolAdd}}</td>
                                </tr>
                                <tr>
                                    <td>Phone Number</td>
                                    <td>{{school_ContactNo}}</td>
                                </tr>
                                <tr>
                                    <td>Candidate List</td>
                                    <td><button class="btn btn-primary" [routerLink]="['/showSchoolcandidatelist',schoolid,schoolName]">View List</button></td>
                                </tr>
                                <tr>
                                    <td>Payment Details</td>
                                    <td><button class="btn btn-primary" [routerLink]="['/schoolpaymentlist',schoolid,schoolName]">View Payment Details</button></td>
                                 </tr>
                            </tbody>
                       </table> 
                  </div>
                </div>
              </div>
        </div>
        <!-- <div class="col-md-4">
            <div class="card">
                <h5 class="card-header cardheader"><i class="bi bi-border-width"></i> View School Payment Details</h5>
                <div class="card-body">
                  <div class="hsscdiv" >
                       <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>School Id</th>
                                    <th>{{schoolid}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Payment Amount</td>
                                    <td>{{schoolPayAmt}}</td>
                                </tr>
                                <tr>
                                    <td>Payment status</td>
                                    <td>{{schoolPaySatus}}</td>
                                </tr>
                                <tr>
                                    <td>Payment Mode</td>
                                    <td>{{schoolPayMode}}</td>
                                </tr>
                            </tbody>
                       </table> 
                  </div>
                </div>
              </div>
            
            
        </div> -->
        
    </div>
  
    <div class="row mb-3"></div>
</div>