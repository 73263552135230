<div class="col-sm-2 upperTitle">
  <button class="btn" style="margin-bottom:-50px; font-size: 25px;" onclick="history.back()"><i class="bi bi-arrow-left-circle-fill"></i></button>
</div>
<div class="container login-container bg-light mb-3">
  
  <div class="clsUpdateStatus row mt3 ">  
    <div class="col-sm-2 upperTitle">
           <div class="titleText">School Id</div> 
           <div class="titleValue">{{schoolid}}</div>
    </div>
    <div class="col-sm-5 upperTitle">
          <div class="titleText">School Name</div> 
          <div class="titleValue">{{schoolName}}</div>                
    </div>
    <div class="col-sm-3 upperTitle">               
          <div class="titleText"> Total No of Transaction</div> 
          <div class="titleValue">{{totalNoOfTrans}}</div>  
    </div>
    <div class="col-sm-2 upperTitle">               
      <div class="titleText"> Total Amount</div> 
      <div class="titleValue">{{totalAmt}}</div>  
</div>
</div>  
  <br>
    <div class="row mb-3 justify-content-md-center">
         
        <div class="col-md-12">
            <div class="card">
                <h5 class="card-header cardheader"><i class="bi bi-wallet2"></i> View School Payment Lsit</h5>
                <div class="card-body">
                  
                    <div class="hsscdiv" >
                        <table class="table table-bordered">
                            <thead>
                                <tr class="headerRow">
                                  <th>Sr No</th>
                                  <th>Transaction ID</th>
                                  <th>Amount</th>
                                  <th>Payment Mode</th>
                                  <th>Status</th>
                                  <!-- <th>Action</th> -->
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let trans of allPayTrans; let i = index ">
                                  <td>{{i+1}}</td>
                                  <td>{{trans.paymentid}}</td>
                                  <td>{{trans.amount}}</td>
                                  <td>{{trans.payment_type}}</td>
                                  <td class="smallClm2"><span class="badge" [ngClass]="{ 
                                    'badge-success': trans.status === 'Paid',
                                  'badge-warning': trans.status === 'pending'}" >{{trans.status}}</span></td>
                                </tr>                                
                              </tbody>
                            </table>
                   
                    </div>
                    
                    
                </div>
              </div>
        </div>
       
    </div>
  
    <div class="row mb-3"></div>
</div>