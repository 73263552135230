import { Injectable } from '@angular/core';
function _window() : any {
  // return the global native browser window object
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  visible: boolean;
    dashboard: boolean;
    boardadmin:boolean;
    schooladmin:boolean;
  IXthstudent: boolean;
  XIthstudent: any;
  boarduser: boolean;

    get nativeWindow() : any {
      return _window();
   }

  constructor() { this.visible = false; this.dashboard =false ; this.boardadmin = false; 
    this.schooladmin = false; this.IXthstudent = false; this.XIthstudent = false; this.boarduser = false}

  hide() { this.visible = false; }

  show() { this.visible = true; }

  toggle() { this.visible = !this.visible; }

  doSomethingElseUseful() { }


  dhide() { this.dashboard = false; }

  dshow() { this.dashboard = true; }

  dtoggle() { this.dashboard = !this.visible; }

  ddoSomethingElseUseful() { }


  adhide() { this.boardadmin = false; }

  adshow() { this.boardadmin = true; }

  adtoggle() { this.boardadmin = !this.visible; }

  addoSomethingElseUseful() { }

  aduserhide() { this.boarduser = false; }

  adusershow() { this.boarduser = true; }

  adusertoggle() { this.boarduser = !this.visible; }

  aduserdoSomethingElseUseful() { }

  asdhide() { this.schooladmin = false; }

  asdshow() { this.schooladmin = true; }

  asdtoggle() { this.schooladmin = !this.visible; }

  asddoSomethingElseUseful() { }

  studhide() { this.IXthstudent = false; }

  studshow() { this.IXthstudent = true; }

  studtoggle() { this.IXthstudent = !this.visible; }

  studdoSomethingElseUseful() { }
  
  XIstudhide() { this.XIthstudent = false; }

  XIstudshow() { this.XIthstudent = true; }

  XIstudtoggle() { this.XIthstudent = !this.visible; }

  XIstuddoSomethingElseUseful() { }

 
}

