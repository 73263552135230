<div class="container login-container bg-light mb-3">
    <br>
    <div class="row mb-3 justify-content-md-center">
         
        <div class="col-md-12">
            <div class="card">
                <h5 class="card-header cardheader"><i class="bi bi-border-width"></i> View Schools</h5>
                <div class="card-body">
                  
                    <div class="hsscdiv" >

                      <p-table #dt [value]="schooldetails" [(selection)]="selectedSchooldetails" dataKey="id" styleClass="p-datatable-customers" class="tablescroll" [rowHover]="true"
        [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0" [globalFilterFields]="['schoolid','schoolname']">
        <ng-template pTemplate="caption">
            <div class="table-header">
                &nbsp;
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal(getEventValue($event), 'contains')" placeholder="Search School Name or School ID" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr class="headerRow">
                <!-- <th style="width: 3rem"></th>
                <th pSortableColumn="Action" style="width: 165px;">Action <p-sortIcon field="action"></p-sortIcon></th>
                 -->
                <th pSortableColumn="school_ID" class="smallClm">School Id <p-sortIcon field="schoolid"></p-sortIcon></th>
                <th pSortableColumn="schoolname" class="smallClm1" >School Name <p-sortIcon field="schoolname"></p-sortIcon></th>
                <th pSortableColumn="Action" class="smallClm">Action <p-sortIcon field="action"></p-sortIcon></th>
            </tr>
            
        </ng-template>
        <ng-template pTemplate="body" let-schools>
            <tr class="p-selectable-row">          
                <td class="smallClm">
                  <span class="p-column-title">school_ID</span>
                  {{schools.schoolid}}
              </td>
                <td class="smallClm1">
                    <span class="p-column-title">school Name</span>
                    {{schools.schoolname}}
                </td>
                <td class="smallClm">
                  <a aria-current="page"  [routerLink]="['/viewSchooldetails',schools.schoolid]"><button class="btn btn-outline-primary btn-sm btnEdit"><i class="bi bi-eye-fill tblbtn"></i></button></a>
                </td>
              
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">No Schools found.</td>
            </tr>
        </ng-template>
        </p-table>
     </div>
  </div>
</div>
</div>
</div>
<div class="row mb-3"></div>
</div>