import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../default/navbar/navbar.service';

@Component({
  selector: 'app-page-under-maintenance',
  templateUrl: './page-under-maintenance.component.html',
  styleUrls: ['./page-under-maintenance.component.scss']
})
export class PageUnderMaintenanceComponent implements OnInit {

  constructor(public nav:NavbarService) { }

  ngOnInit(): void {
    this.nav.show();
    this.nav.dhide();//studentdash
    this.nav.adhide();//boardadmindash
    this.nav.asdhide(); //schooladmin
  }

}
