<script>
	$('.select2').select2(function(){alert(111)});
</script>
<div class="container">
    <div class="schoolDetailWrap  pt-5">
        <div class="card shadow">
            <div class="card-header cardHeader">
              Add School Enrolment Details
            </div>
            <div class="card-body">
              <h5 class="card-title"></h5>
              <div class="SchooldetailContent">
                <form>
                    <div class="form-group">
                      <label for="schoolEnrollYear">Enter School Enrolment Year</label>
                      <input type="text" class="form-control" id="schoolEnrollYear" aria-describedby="emailHelp" placeholder="Enter Year of enrolment">
                      <!-- <small id="emailHelp" class="form-text text-muted"></small> -->
                    </div>
                    <div class="form-group">
                      <label>Select School Name</label>
                      <ng-select2 
                            [(ngModel)]="schoolName"
                            [data]="schoolList"
                            [placeholder]="'Please select a School...'"
                            width="100%"
                            height="20px"
                            >		
                      </ng-select2>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="addSchoolCheck" (change)="enableBtnEnroll($event)">
                      <label class="form-check-label" for="addSchoolCheck">Checked and Confirm</label>
                    </div>
                   </form>
              </div>
              <div class="btnaddSchool">
                <button  class="btn btn-primary" [disabled]=btnEnrollDisable>Enroll School</button>
              </div>
            </div>
        </div>
    </div>
</div>