<div class="container login-container bg-light mb-3">
  <br>
  <div class="card border-dark mb-3">
      <h5 class="card-header">Reset Password</h5>
      <div class="card-body text-dark ">
          <!-- <figcaption class="blockquote" style="color: #0062cc;">
              <cite title="Source Title"><b>- Personal Information</b></cite>
           </figcaption> <hr> -->
           <form role="form" [formGroup]= "boarduserresetform">
               <div  class="justify-content-md-center">
              
                 
                    
                    <div class="form-group row mb-3">
                      <label for="username" class="col-sm-2 col-form-label">User's Full Name</label>
                      <div class="col-sm-5 sel2">
                          <ng-select2 
                                [(ngModel)]="bUserName"
                                [data]="allBoardUsersFullname"
                                
                                [placeholder]="'Please select User Name'"
                                width="100%"                                 
                                formControlName="username"    
                                style="margin: 0px; padding: 0px;"
                                class="form-control"  [ngClass]="{ 'is-invalid': submitted && personal.username.errors }"
                                >
                          </ng-select2> 
                        <div *ngIf="submitted && personal.username.errors" class="invalid-feedback">
                           <div *ngIf="personal.username.errors.required">
                             User's Name Is Required.
                           </div>
                         </div>
               
                      </div>
                    </div>
                    <!-- <div class="form-group row mb-3">
                      <label for="staticdob" class="col-sm-2 col-form-label">Current Password</label>
                      <div class="col-sm-5">
                        <input type="password" formControlName="password" class="form-control" id="inputpassword" [ngClass]="{ 'is-invalid': submitted && personal.password.errors }">
                        <div *ngIf="submitted && personal.password.errors" class="invalid-feedback">
                          <div *ngIf="personal.password.errors.required">
                              Password Is Required.
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="form-group row mb-3">
                      <label for="staticdob" class="col-sm-2 col-form-label">New Password</label>
                      <div class="col-sm-5">
                        <input type="password" formControlName="new_password" class="form-control" id="inputpassword" [ngClass]="{ 'is-invalid': submitted && personal.new_password.errors }">
                        <div *ngIf="submitted && personal.new_password.errors" class="invalid-feedback">
                          <div *ngIf="personal.new_password.errors.required">
                              New Password Is Required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label"></label>
                        <!-- <input type="button" class="btnSubmit btn btn-success"  value="Save Changes" (click)="savedata()" /> &nbsp; -->
                        <input type="button" class="btnSubmit btn btn-primary"  value="Submit" (click)="onsubmit()" /> 
                        <!-- (click)="onstudentprofilesubmit(studentprofileform.value)" -->
                     </div>
                    
                  </div>
                
             
            </form>
            
      </div>
    </div>
    <div class="row mb-3"></div>
</div>
