<footer class="bg-light text-center text-lg-start footer foter"  >
    
    <div class="text-center p-3" style="background-color:  #061665;font-size: 10px;color: #f8f9fa;">
     <a class="text">©This is the official website of Goa Board of Secondary and Higher Secondary Education
      Alto Betim - Goa 403 521, India. All Rights Reserved.</a><br>
      <!-- Developed By 
        <a class="text-dark" href="http://edhaasdigisoft.com/" ><b>Edhaas DigiSoft Private Limited</b></a> -->
    </div>
    
  </footer>
  