
<!-- <div *ngIf="showDiv.previous">
  <div class="container hsscdiv">

        <div class="col-sm-12">
          <div class="row mt3">
          <div class="col-sm-6">
            <p>
              <a class="btn btn-primary" data-toggle="collapse" href="#icicipay" role="button" aria-expanded="false" aria-controls="icicipay">
                ICICI Pay
              </a> 
             
            </p>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#rozorpay" aria-expanded="false" aria-controls="rozorpay">
              ROZORPAY
            </button>
          </div>
        </div>

        </div>

    <div class="collapse" id="icicipay">
      <div class="container main-cont">  
        <div class="paymentScreenWrapper">
          
            <div class="d-flex paymentScreenbg shadow">
                <div class=" sec1">
                    <div class="paymentDetailsWrap">
                        <h5 class="headingValue mb-5 underline">Online Fee Payment:</h5>
                        <div class="payableDetails">
                            <p class="headingName"><i class="bi bi-bank2 mr-2"></i>Name of School</p>
                            <h3 class="headingValue">Star City School</h3>
                        </div>
                        <div class="payableDetails">
                            <p class="headingName pt-2"><i class="bi bi-people mr-2"></i>Number of Student</p>
                            <h3 class="headingValue">10</h3>
                        </div>
                        <div class="payableDetails">
                            <p class="headingName pt-2"><i class="bi bi-calculator mr-2"></i>Total Amount Payable</p>
                            <h3 class="headingValue">10,000</h3>
                        </div>
                    </div>
                </div>
                
                <div class=" sec2">
                  <i [routerLink]="['/candidates-payment']" class="bi bi-x-square-fill btnClose"></i>
                    <p class="impNote">NOTE: In case of payment, if respected amount is deducted from account and 
                        payment deduction message has been received then kindly wait for 72 hours
                        before reinitializing the payment process.
                    </p>
                    <div class="payOptionsWrap">
                         <div class="form-check">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" name="optradio">Debit Card
                            </label>
                          </div>
                          <div class="form-check">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" name="optradio">Credit Card
                            </label>
                          </div>
                          <div class="form-check disabled">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" name="optradio" >UPI
                            </label>
                          </div>
                          <div class="form-check disabled">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" name="optradio" >Internet Banking
                            </label>
                          </div>
                          <div class="clsCkeck">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input type="checkbox" (change)="funChecked($event)" class="form-check-input" name="optradio">I agree to pay fees online.
                                </label>
                            </div>
                          </div>
                          
                          <div class="d-flex flex-row-reverse mt-5">
                            <button type="button" class="btn btn-primary" (click)="Pay()" [disabled]="checkTicked">Pay Online</button>
                          </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="collapse" id="rozorpay">
      <div class="container main-cont">  
        <div class="paymentScreenWrapper">
          
            <div class="d-flex paymentScreenbg shadow">
                <div class=" sec1">
                    <div class="paymentDetailsWrap">
                        <h5 class="headingValue mb-5 underline">Online Fee Payment:</h5>
                        <div class="payableDetails">
                            <p class="headingName"><i class="bi bi-bank2 mr-2"></i>Name of School</p>
                            <h3 class="headingValue">Star City School</h3>
                        </div>
                        <div class="payableDetails">
                            <p class="headingName pt-2"><i class="bi bi-people mr-2"></i>Number of Student</p>
                            <h3 class="headingValue">10</h3>
                        </div>
                        <div class="payableDetails">
                            <p class="headingName pt-2"><i class="bi bi-calculator mr-2"></i>Total Amount Payable</p>
                            <h3 class="headingValue">10,000</h3>
                        </div>
                    </div>
                </div>
                
                <div class=" sec2">
                  <i [routerLink]="['/candidates-payment']" class="bi bi-x-square-fill btnClose"></i>
                    <p class="impNote">NOTE: In case of payment, if respected amount is deducted from account and 
                        payment deduction message has been received then kindly wait for 72 hours
                        before reinitializing the payment process.
                    </p>
                    <div class="payOptionsWrap">
                         
                          <div class="clsCkeck">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input type="checkbox" (change)="funChecked($event)" class="form-check-input" name="optradio">I agree to pay fees online.
                                </label>
                            </div>
                          </div>
                          
                          <div class="d-flex flex-row-reverse mt-5">
                            <button type="button" class="btn btn-primary" (click)="Pay()" [disabled]="checkTicked">Pay Online</button>
                          </div>
                    </div>
            </div>
        </div>
    </div>
    </div>
  
  </div>
</div>
</div> -->
<div class="container py-5" *ngIf="showDiv.previous">
  <!-- For demo purpose -->
  <div class="row mb-4">
      <div class="col-lg-8 mx-auto text-center">
          <!-- <h1 class="display-6">Bootstrap Payment Forms</h1> -->
      </div>
  </div> <!-- End -->
  <div class="row">
    <div class="col-lg-10 mx-auto">
      <div class="card ">
        <div class="card-header">
            <div class="bg-white shadow-sm pt-4 pl-2 pr-2 pb-2">
                <!-- Credit card form tabs -->
                <ul role="tablist" class="nav bg-light nav-pills rounded nav-fill mb-3">
                    <li class="nav-item"> <a data-toggle="pill" href="#credit-card" class="nav-link active "> <i class="fas fa-credit-card mr-2"></i> ICICI PAY </a> </li>
                    <li class="nav-item"> <a data-toggle="pill" href="#paypal" class="nav-link "> <i class="fab fa-paypal mr-2"></i> RAZORPAY </a> </li>
                    <!-- <li class="nav-item"> <a data-toggle="pill" href="#net-banking" class="nav-link "> <i class="fas fa-mobile-alt mr-2"></i> Net Banking </a> </li> -->
                </ul>
            </div> <!-- End -->
            <div class="tab-content">
              <!-- credit card info-->
              <div id="credit-card" class="tab-pane fade show active pt-3">
                <div class="container main-cont">  
                  <div class="paymentScreenWrapper">
                    
                      <div class="d-flex paymentScreenbg shadow">
                          <div class=" sec1">
                              <div class="paymentDetailsWrap">
                                  <h5 class="headingValue mb-5 underline">Online Fee Payment:</h5>
                                  <div class="payableDetails">
                                      <p class="headingName"><i class="bi bi-bank2 mr-2"></i>Name of School</p>
                                      <h5 class="headingValue">{{schoolname}}</h5>
                                  </div>
                                  <div class="payableDetails">
                                      <p class="headingName pt-2"><i class="bi bi-people mr-2"></i>Number of Student</p>
                                      <h3 class="headingValue">{{selectedCandidates?.length}}</h3>
                                  </div>
                                  <div class="payableDetails">
                                      <p class="headingName pt-2"><i class="bi bi-calculator mr-2"></i>Total Amount Payable</p>
                                      <h3 class="headingValue">{{totalAmountCalculated}}</h3>
                                  </div>
                              </div>
                          </div>
                          
                          <div class=" sec2">
                            <i (click)="showDiv.previous = !showDiv.previous;showDiv.current = true" class="bi bi-x-square-fill btnClose"></i>
                              <p class="impNote">NOTE: In case of payment, if respected amount is deducted from account and 
                                  payment deduction message has been received then kindly wait for 72 hours
                                  before reinitializing the payment process.
                              </p>
                              <div class="payOptionsWrap">
                                   <div class="form-check">
                                      <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="optradio">Debit Card
                                      </label>
                                    </div>
                                    <div class="form-check">
                                      <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="optradio">Credit Card
                                      </label>
                                    </div>
                                    <div class="form-check disabled">
                                      <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="optradio" >UPI
                                      </label>
                                    </div>
                                    <div class="form-check disabled">
                                      <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="optradio" >Internet Banking
                                      </label>
                                    </div>
                                    <div class="clsCkeck">
                                      <div class="form-check">
                                          <label class="form-check-label">
                                          <input type="checkbox" (change)="funChecked($event)" class="form-check-input" name="optradio">I agree to pay fees online.
                                          </label>
                                      </div>
                                    </div>
                                    
                                    <div class="d-flex flex-row-reverse mt-5">
                                      <button type="button" class="btn btn-primary" (click)="paywithicici()" [disabled]="checkTicked">Pay Online</button>
                                    </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>                
              </div>
              <div id="paypal" class="tab-pane fade pt-3">
                <div class="container main-cont">  
                  <div class="paymentScreenWrapper">
                    
                      <div class="d-flex paymentScreenbg shadow">
                          <div class=" sec1">
                              <div class="paymentDetailsWrap">
                                  <h5 class="headingValue mb-5 underline">Online Fee Payment:</h5>
                                  <div class="payableDetails">
                                      <p class="headingName"><i class="bi bi-bank2 mr-2"></i>Name of School</p>
                                      <h5 class="headingValue">{{schoolname}}</h5>
                                  </div>
                                  <div class="payableDetails">
                                      <p class="headingName pt-2"><i class="bi bi-people mr-2"></i>Number of Student</p>
                                      <h3 class="headingValue">{{selectedCandidates?.length}}</h3>
                                  </div>
                                  <div class="payableDetails">
                                      <p class="headingName pt-2"><i class="bi bi-calculator mr-2"></i>Total Amount Payable</p>
                                      <h3 class="headingValue">{{totalAmountCalculated}}</h3>
                                  </div>
                              </div>
                          </div>
                          
                          <div class=" sec2">
                            <i (click)="showDiv.previous = !showDiv.previous;showDiv.current = true" class="bi bi-x-square-fill btnClose"></i>
                              <p class="impNote">NOTE: In case of payment, if respected amount is deducted from account and 
                                  payment deduction message has been received then kindly wait for 72 hours
                                  before reinitializing the payment process.
                              </p>
                              <div class="payOptionsWrap">
                                   <div class="form-check">
                                      <!-- <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="optradio">Debit Card
                                      </label> -->
                                    </div>
                                    <div class="form-check">
                                      <!-- <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="optradio">Credit Card
                                      </label> -->
                                    </div>
                                    <div class="form-check disabled">
                                      <!-- <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="optradio" >UPI
                                      </label> -->
                                    </div>
                                    <div class="form-check disabled">
                                      <!-- <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="optradio" >UPI
                                      </label> -->
                                    </div>
                                    
                                    <div class="clsCkeck">
                                      <div class="form-check">
                                          <label class="form-check-label">
                                          <input type="checkbox" (change)="funChecked($event)" class="form-check-input" name="optradio">I agree to pay fees online.
                                          </label>
                                      </div>
                                    </div>
                                    
                                    <div class="d-flex flex-row-reverse mt-5">
                                      <button type="button" class="btn btn-primary" (click)="Pay()" [disabled]="checkTicked">Pay Online</button>
                                    </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>    
            </div> <!-- End -->
            </div>
          </div>
        </div>
    </div>
  </div>
</div>


<div *ngIf="showDiv.current">
  <div class="container">
    
    <div class="row  mt3">
      
      <div class="col-sm-10" ></div>
     <!-- <div class="col-sm-2 clstopBtn">
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">Proceed to Pay</button>
    </div> -->
    </div>
  
  </div>

  
  <div class="container-fluied tableBody">
    <div class="row">
      <div class="col-sm-12 " >
  
        <p-table #dt [value]="students" [(selection)]="selectedCandidates" dataKey="studentid" styleClass="p-datatable-customers" class="tablescroll" [rowHover]="true"
        [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100,1000,{ showAll: 'All' }]" [loading]="loading"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0" [globalFilterFields]="['username','stname','division','submission_status','pmt_status','Amount']">
       
       <ng-template pTemplate="caption">
          <div class="table-header">
              Candidate Payment Details
              <span class="p-input-icon-left ">
                  <i class="pi pi-search "></i>
                  <input class="clsSearch" pInputText type="text" (input)="dt.filterGlobal(getEventValue($event), 'contains')" placeholder="Search in table" />
              </span>
          </div>
      </ng-template>
      <ng-template pTemplate="header">
          <tr class="headerRow">
              <th style="width: 3rem"></th>              
              <!-- <th pSortableColumn="studentid" style="width: 165px;">Student Id <p-sortIcon field="studentid"></p-sortIcon></th> -->
              <th pSortableColumn="username" style="width: 165px;">Username <p-sortIcon field="username"></p-sortIcon></th>
              <th pSortableColumn="stname" style="width: 165px;">Student Name <p-sortIcon field="stname"></p-sortIcon></th>
              <th pSortableColumn="division" style="width: 165px;">Class /Div <p-sortIcon field="division"></p-sortIcon></th>
              <th pSortableColumn="submission_status" style="width: 165px;">Regist Status <p-sortIcon field="submission_status"></p-sortIcon></th>
              <th pSortableColumn="pmt_status" style="width: 145px;" >Payment Status <p-sortIcon field="pmt_status"></p-sortIcon></th>
              <th class="smallCol2" pSortableColumn="pmt_ID" style="width: 165px;">Payment ID <p-sortIcon field="pmt_ID"></p-sortIcon></th>
              <th pSortableColumn="Amount" style="width: 165px;">Amount <p-sortIcon field="Amount"></p-sortIcon></th>
             
          </tr>
          <tr>
              <th class="selectAllChk">
                  <p-tableHeaderCheckbox  (click)="allselectRow()"></p-tableHeaderCheckbox>
              </th>
              
              <th>
                <span class="p-input-icon-left"><i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filter(getidEventValue($event), 'username', 'startsWith')" placeholder="Username" class="p-column-filter">
                  </span>
              </th>
              <th>
                <span class="p-input-icon-left"><i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filter(getnameEventValue($event),'stname','contains')" placeholder="Student Name" class="p-column-filter">
                 </span> 
              </th>
              <th>
                <span class="p-input-icon-left"><i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filter(getdivisionClassEventValue($event), 'division', 'contains')" placeholder="Division" class="p-column-filter">
                </span>  
              </th>
              <th>
                <span class="p-input-icon-left"><i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filter(getresigstatusEventValue($event), 'submission_status', 'startsWith')" placeholder="Registration Status" class="p-column-filter">
                  </span>
              </th>
              <th>
                <span class="p-input-icon-left"><i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filter(getpaymentstatusEventValue($event), 'pmt_status', 'startsWith')" placeholder="Payment Status" class="p-column-filter">
                </span>    
              </th>              
              <th>
                <span class="p-input-icon-left"><i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filter(getpaymentidValue($event), 'PaymentID', 'contains')" placeholder="pmt_ID" class="p-column-filter">
                </span>  
              </th>
              <th>
                <span class="p-input-icon-left"><i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filter(getpaymentAmountValue($event), 'Amount', 'contains')" placeholder="Amount" class="p-column-filter">
                </span>  
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-students class="tablescroll">
          <tr class="p-selectable-row">
              <td>
                  <p-tableCheckbox [value]="students" (click)="selectRow()"></p-tableCheckbox>
              </td>
              
              <!-- <td>
                <span class="p-column-title"></span>
                {{students.studentid}}
            </td> -->
            <td>
              <span class="p-column-title"></span>
              {{students.username}}
          </td>
              <td>
                  <span class="p-column-title"></span>
                  {{students.stname}}
              </td>
              
              <td>
                <span class="p-column-title"></span>
                {{students.division}}
      
            </td>
            <td>
                <span class="p-column-title"></span>
                {{students.submission_status}}
                
            </td>
            <td>
              <span class="p-column-title"></span>
              {{students.pmt_status}}
          </td>
          <td>
            <span class="p-column-title"></span>
            {{students.pmt_ID}}
        </td>
          <td>
            <span class="p-column-title"></span>
            <i class="fa fa-rupee"></i> {{amount}}
          </td>
         
          </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
          <tr>
              <td colspan="8">No Students found.</td>
          </tr>
      </ng-template>
     
  
        </p-table>
     </div>
   
      
  </div>
  </div>
</div>

        <!-- Pup up payment confirmation -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Payment Confirmation</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <h4>Confirm Payment Amount!</h4>
            <table class="table-borderless">
                <tr>
                  <td>Number Of Student -</td>
                  <td>{{studentlength}}</td>
                </tr>
                <tr>
                  <td>Total Amount Payable -</td>
                  <td>{{totalAmountCalculated}}</td>
                </tr>
            </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
           
           <!-- <button type="button" class="btn btn-primary" [routerLink]="['/payment']" data-dismiss="modal">Proceed for Online Payment</button> -->
           <button type="button" class="btn btn-primary" (click)="proceed()"  data-dismiss="modal">Proceed for Online Payment</button>
        
        </div>
      </div>
    </div>
  </div>
  