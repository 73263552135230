<div class="container login-container bg-light mb-3">
    <br>
    <div class="card border-dark mb-3 justify-content-center">
      <h5 class="card-header" style="background-color: #05a7c5c2;"><i class="bi bi-tags-fill"></i>&nbsp;&nbsp; Unregistered Schools</h5>
      <div class="card-body text-dark ">
       
            <ul class="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist" >
                <li class="nav-item">
                  <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#std9" role="tab" aria-controls="Standard" aria-selected="true">Unregistered 9th Schools</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#std11" role="tab" aria-controls="Student" aria-selected="false">Unregistered 11th Schools</a>
                </li>
               
              </ul>
       
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="std9" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="col-md-12">
                    <div class="card">
                        <!-- <h5 class="card-headerNest cardheader"><i class="bi bi-border-width"></i> View Schools</h5> -->
                        <div class="card-body">
                          
                            <div class="hsscdiv" >
        
                              <p-table #dt [value]="schooldetails" [(selection)]="selectedSchooldetails" dataKey="id" styleClass="p-datatable-customers" class="tablescroll" [rowHover]="true"
                [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
                [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [filterDelay]="0" [globalFilterFields]="['schoolid','schoolname']">
                <ng-template pTemplate="caption">
                    <div class="table-header">
                        &nbsp;
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal(getEventValue($event), 'contains')" placeholder="Search School Name or School ID" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr class="headerRow">
                        <!-- <th style="width: 3rem"></th>
                        <th pSortableColumn="Action" style="width: 165px;">Action <p-sortIcon field="action"></p-sortIcon></th>
                         -->
                        <th pSortableColumn="school_ID" class="smallClm">School Code <p-sortIcon field="schoolid"></p-sortIcon></th>
                        <th pSortableColumn="schoolname" class="smallClm1" >School Name <p-sortIcon field="schoolname"></p-sortIcon></th>
                        <th pSortableColumn="Action" class="smallClm">School Contact <p-sortIcon field="action"></p-sortIcon></th>
                        <th pSortableColumn="Action" class="smallClm">Address <p-sortIcon field="action"></p-sortIcon></th>
                    </tr>
                    
                </ng-template>
                <ng-template pTemplate="body" let-schools>
                    <tr class="p-selectable-row">          
                        <td class="smallClm">
                          <!-- <span class="p-column-title">school_ID</span> -->
                          {{schools.schoolid}}
                      </td>
                        <td class="smallClm1">
                            <!-- <span class="p-column-title">school Name</span> -->
                            {{schools.schoolname}}
                        </td>
                        <td class="smallClm">
                            {{schools.mobileno1}}
                        </td>
                        <td class="smallClm">
                            {{schools.schooladdress}} {{schools.pincode}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">No Schools found.</td>
                    </tr>
                </ng-template>
                </p-table>
             </div>
          </div>
        </div>
        </div>
            </div>
            <div class="tab-pane fade" id="std11" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="col-md-12">
                    <div class="card">
                        <!-- <h5 class="card-headerNest cardheader"><i class="bi bi-border-width"></i> View Schools</h5> -->
                        <div class="card-body">
                          
                            <div class="hsscdiv" >
        
                              <p-table #dt [value]="infoschool" [(selection)]="selectedeleSchooldetails" dataKey="id" styleClass="p-datatable-customers" class="table table-bordered tablescroll" [rowHover]="true"
                [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
                [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [filterDelay]="0" [globalFilterFields]="['schoolid','schoolname']">
                <ng-template pTemplate="caption">
                    <div class="table-header">
                        &nbsp;
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal(getEventValue($event), 'contains')" placeholder="Search School Name or School ID" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr class="headerRow">
                        <!-- <th style="width: 3rem"></th>
                        <th pSortableColumn="Action" style="width: 165px;">Action <p-sortIcon field="action"></p-sortIcon></th>
                         -->
                        <th pSortableColumn="school_ID" class="smallClm">School Code <p-sortIcon field="schoolid"></p-sortIcon></th>
                        <th pSortableColumn="schoolname" class="smallClm1" >School Name <p-sortIcon field="schoolname"></p-sortIcon></th>
                        <th pSortableColumn="Action" class="smallClm">School Contact <p-sortIcon field="action"></p-sortIcon></th>
                        <th pSortableColumn="Action" class="smallClm">Address <p-sortIcon field="action"></p-sortIcon></th>
                    </tr>
                    
                </ng-template>
                <ng-template pTemplate="body" let-eleschools>
                    <tr class="p-selectable-row">          
                        <td class="smallClm">
                          <!-- <span class="p-column-title">school_ID</span> -->
                          {{eleschools.schoolid}}
                      </td>
                        <td class="smallClm1">
                            <!-- <span class="p-column-title">school Name</span> -->
                            {{eleschools.schoolname}}
                        </td>
                        <td class="smallClm">
                            {{eleschools.mobileno1}}
                        </td>
                        <td class="smallClm">
                            {{eleschools.schooladdress}} {{eleschools.pincode}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">No Schools found.</td>
                    </tr>
                </ng-template>
                </p-table>
             </div>
          </div>
        </div>
        </div>
            </div>
          
        </div>
        </div>
    </div>
</div>