<div class="container dashboardWrapper">
    <div class="dashboardCont">
        <div class="row">
            <div class="col-sm-12">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">Last Dates</h4>
                     <div><!--  last dates for 9th -->
                        <div class="card-body">   
                            <div class="lastDateText"> 
                                <h5 >Last Date of 9th Candidates Registration</h5>
                                <div class="clsLastDate clsBlinkingtxt">{{lastdatestud_9th}}</div>
                            </div>
							<div class="lastDateText"> 
                                <h5 >Last Date of 11th Candidates Registration</h5>
                                <div class="clsLastDate clsBlinkingtxt">{{lastdatestud_11th}}</div>
                            </div>
                            <div class="lastDateText"> 
                                <h5 >Last Date of 9th Submission for School</h5>
                                <div class="clsLastDate clsBlinkingtxt">{{lastdate_9th}}</div>
                            </div>                            
                            <div class="lastDateText"> 
                                <h5 >Last Date of 11th Submission for School</h5>
                                <div class="clsLastDate clsBlinkingtxt">{{lastdate_11th}}</div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>  
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{registration9_title}}</h4>
                    <div class="card-body ">
                        <div *ngIf="!regi9StatusGhraphShow" class="chartadjust">
                            <google-chart #chart                
                            [type]="registration9_type"
                            [data]="registration9_data"                
                            [options]="registration9_options"
                            [width]="registration9_width"
                            [height]="registration9_height">                       
                        </google-chart>
                        </div>
                        <div *ngIf="regi9StatusGhraphShow" class="alert alert-danger m-3 py-3" role="alert">
                            <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                            <a class="alert-link">Registration Status Data</a><br> is not available.
                        </div> 
                        <!-- <div class="custome-legend">
                        <span class="left-cont">{{registration9_data[0][0]}} : {{registration9_data[0][1]}}</span>
                        <span class="right-cont">{{registration9_data[1][0]}} : {{registration9_data[1][1]}}</span>
                        </div> -->
                    </div>
                </div>
            </div> 
            <div class="col-sm-6">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{registration11_title}}</h4>
                    <div class="card-body">    
                        <div *ngIf="!regi11StatusGhraphShow" class="chartadjust">
                            <google-chart #chart                
                            [type]="registration11_type"
                            [data]="registration11_data"                
                            [options]="registration11_options"
                            [width]="registration11_width"
                            [height]="registration11_height">
                        </google-chart>
                        </div> 
                        <div *ngIf="regi11StatusGhraphShow" class="alert alert-danger m-3 py-3" role="alert">
                            <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                            <a class="alert-link">Registration Status Data</a><br> is not available.
                        </div> 
                        <!-- <div class="custome-legend">
                            <span class="left-cont">{{registration11_data[0][0]}} : {{registration9_data[0][1]}}</span>
                            <span class="right-cont">{{registration11_data[1][0]}} : {{registration9_data[1][1]}}</span>
                        </div> -->
                    </div>
                </div>
            </div>          
        </div> 
        <div class="row">
            <div class="col-sm-6">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{maleFemale9_title}}</h4>
                    <div class="card-body">   
                        <div *ngIf="!genStatusGhraphShow" class="chartadjust">
                            <google-chart #chart                
                            [type]="maleFemale_type"
                            [data]="maleFemale9_data"                
                            [options]="maleFemale_options"
                            [width]="maleFemale_width"
                            [height]="maleFemale_height">
                        </google-chart>
                        </div> 
                        <div *ngIf="genStatusGhraphShow" class="alert alert-danger m-3 py-3" role="alert">
                            <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                            <a class="alert-link">Male /Female Status Data</a><br> is not available.
                        </div> 
                        <!-- <div class="custome-legend">
                            <span class="left-cont">{{maleFemale_data[0][0]}} : {{registration9_data[0][1]}}</span>
                            <span class="right-cont">{{maleFemale_data[1][0]}} : {{registration9_data[1][1]}}</span>
                        </div> -->
                    </div>
                </div>
            </div> 
            <div class="col-sm-6">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{maleFemale11_title}}</h4>
                    <div class="card-body">  
                        <div *ngIf="!gen11StatusGhraphShow" class="chartadjust">
                            <google-chart #chart                
                            [type]="maleFemale_type"
                            [data]="maleFemale11_data"                
                            [options]="maleFemale_options"
                            [width]="maleFemale_width"
                            [height]="maleFemale_height">
                        </google-chart>
                        </div>  
                        <div *ngIf="genStatusGhraphShow" class="alert alert-danger m-3 py-3" role="alert">
                            <div class="text-center"><i class="bi bi-pie-chart-fill"></i></div>
                            <a class="alert-link">Male /Female Status Data</a><br> is not available.
                        </div> 
                        <!-- <div class="custome-legend">
                            <span class="left-cont">{{maleFemale_data[0][0]}} : {{registration9_data[0][1]}}</span>
                            <span class="right-cont">{{maleFemale_data[1][0]}} : {{registration9_data[1][1]}}</span>
                        </div> -->
                    </div>
                </div>
            </div>  
        </div>
       <!-- <div class="row">
            <div class="col-sm-6">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{weeklyRegistration_title}}</h4>
                    <div class="card-body">    
                        <google-chart #chart
                            [type]="weeklyRegistration_type"
                            [data]="weeklyRegistration_data"
                            [options]="weeklyRegistration_options"
                            [width]="weeklyRegistration_width"
                            [height]="weeklyRegistration_height">
                        </google-chart>
                    </div>
                </div>
            </div> 
            <div class="col-sm-6">
                <div class="card cardCont">
                    <h4 class="clsChartHeading">{{weeklyRegistration_title}}</h4>
                    <div class="card-body">    
                        <google-chart #chart
                            [type]="weeklyRegistration_type"
                            [data]="weeklyRegistration_data"
                            [options]="weeklyRegistration_options"
                            [width]="weeklyRegistration_width"
                            [height]="weeklyRegistration_height">
                        </google-chart>
                    </div>
                </div>
            </div>
        </div>   -->          
        
    </div>
    </div>    