import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../default/navbar/navbar.service';
@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {
  
  constructor(public nav:NavbarService) { 
    

  }
  
  ngOnInit(): void {
    this.nav.show();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdhide();
    this.nav.aduserhide();
      this.nav.studhide();
      this.nav.XIstudhide();


  }
  
}
