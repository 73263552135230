<div class="container-fluied instruction-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 clsNeedHelptxt m-3">
                <h2 class="topHeading">INSTRUCTIONS MANUALS</h2>
            </div> 
        </div>  
        <div class="row intructWrapper border shadow mb-5">     
                <div class="instructionCont mt-5">
                    <h4 class="topHeading">GENERAL INSTRUCTIONS FOR SCHOOL ADMIN</h4>
                            <ul>
                                <li><h5>School Admin (SSC): <a class="font-italic" href="../../assets/instructionspdf/GBSHSE_User_Guide_school_admin_SSC.pdf" download="GENERAL INSTRUCTIONS FOR SCHOOL ADMIN (SSC)">Download</a> </h5></li>
                                <li><h5>School Admin (HSSC): <a  class="font-italic" href="../../assets/instructionspdf/GBSHSE_User_Guide_school_admin__HSSC.pdf" download="GENERAL INSTRUCTIONS FOR SCHOOL ADMIN (HSSC)">Download</a> </h5></li>
                            </ul>
                    <h4 class="topHeading pt-5">GENERAL INSTRUCTIONS FOR CANDIDATES</h4>
                    <ul>
                        <li><h5>Candidates (SSC): <a class="font-italic" href="../../assets/instructionspdf/GBSHSE_User_Guide_Student_Panel_SSC.pdf" download="GENERAL INSTRUCTIONS FOR CANDIDATES (SSC)">Download</a> </h5></li>
                        <li><h5>Candidates (HSSC): <a class="font-italic" href="../../assets/instructionspdf/GBSHSE_User_Guide_Student_Panel_HSSC.pdf" download="GENERAL INSTRUCTIONS FOR CANDIDATES (HSSC)">Download</a> </h5></li>
                    </ul>
                </div>
        </div>

    </div>
            
</div>