import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [DatePipe]
})
export class ForgotPasswordComponent implements OnInit {
  forgotpwdform: FormGroup;
  forgotmail: any;
  submitted = false;

  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) {
    this.forgotpwdform = this.formbuilder.group({
      
      email:['',[Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],

    })
  }

  ngOnInit(): void {
    this.nav.show();
    this.nav.dhide();//studentdash
    this.nav.adhide();//boardadmindash
    this.nav.asdhide(); //schooladmin
    this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();
  }

  get personal() { return this.forgotpwdform.controls; }

  onforgotpwdsubmit(){

    this.submitted = true;
    if (this.forgotpwdform.invalid) {
      
      alert("Fill All Details");
      return;

    }
    let reset : {email:any}={
      email: this.forgotpwdform.controls.email.value
    }

    this.service.post('/StudentApi9/forgotPasswordEmail', reset).subscribe((res:any)=>{
      this.forgotmail = res['Data'];

      if(res['status'] == 'success')
      {
        alert('Mail will be sent');
        window.location.reload();
      }
      else
      {
        alert('The system detected an incorrect operation!!\n Please Try Again!');
      }
     
    },err=>{

      // alert('Mail Not Sent');

    })

  }

}
