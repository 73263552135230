import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../default/navbar/navbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  contactForm: FormGroup;
  submitted=false;
  constructor(public nav:NavbarService,public formbuilder:FormBuilder) { 
    this.contactForm = formbuilder.group({
      contName:['',Validators.required],
      contEmail :['',Validators.required],
      contSub :['',Validators.required],
      contMatter:['',Validators.required]
    })

  }
  get contFormCtrl() { return this.contactForm.controls; }
  ngOnInit(): void {
    this.nav.show();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdhide();
    this.nav.XIstudhide();
    this.nav.studhide();
    this.nav.aduserhide();
  }
  onsubmit(){

    this.submitted = true;
    if (this.contactForm.invalid) {
      
      alert("Fill All Deatils");
      return;
    }
    else{
      this.saveQuery();
    }

  }
  saveQuery(){
    alert("Message Sent to Authorised Person, we will get back to you soon!");
  }
}
