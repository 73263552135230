<div class="container-fluied Contwrapper">
  <div class="col-sm-2 upperTitle">
    <button class="btn" style="margin-bottom:-50px; font-size: 25px;" onclick="history.back()"><i class="bi bi-arrow-left-circle-fill"></i></button>
</div>
  <div class="clsUpdateStatus row mt3 ">  
      <div class="col-sm-2 upperTitle">
             <div class="titleText">School Id</div> 
             <div class="titleValue">{{schoolid}}</div>
      </div>
      <div class="col-sm-4 upperTitle">
            <div class="titleText">School Name</div> 
            <div class="titleValue">{{schoolName}}</div>                
      </div>
      <div class="col-sm-4 upperTitle">               
            <div class="titleText"> Total No of Students</div> 
            <div class="titleValue">{{totalNoOfStudents}}</div>  
      </div>
  </div>
  <div class="row">
      <div class="col-sm-12 tablescroll" >
  
        <p-table #dt [value]="schoolCandidates" scrollHeight="600px"  dataKey="username" styleClass="p-datatable-customers" [rowHover]="false"
        [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0" [globalFilterFields]="['username','stream','course','rollno','stname','ftname','mtname','gender','division','submission_status','pmt_status','pmt_ID']">
       
       <ng-template pTemplate="caption">
          <div class="table-header">
              Candidate Details
              <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filterGlobal(getEventValue($event), 'contains')" placeholder="Search" />
              </span>
          </div>
      </ng-template>
      <ng-template pTemplate="header">
          <tr class="headerRow">
              
              <th class="smallCol2" pSortableColumn="studentid">User Name<p-sortIcon field="username"></p-sortIcon></th>
              <th *ngIf="streamcourseShow == true" class="smallCol2" pSortableColumn="stream">Stream<p-sortIcon field="stream"></p-sortIcon></th>
              <th *ngIf="streamcourseShow == true" class="smallCol2" pSortableColumn="course">Course<p-sortIcon field="course"></p-sortIcon></th>
              <th class="smallCol" pSortableColumn="rollno" >Roll No<p-sortIcon field="rollno"></p-sortIcon></th>
              <th class="smallCol" pSortableColumn="division" >Division<p-sortIcon field="division"></p-sortIcon></th>
              <th pSortableColumn="stname" style="width: 165px;">Student Name <p-sortIcon field="stname"></p-sortIcon></th>
              <th pSortableColumn="ftname" style="width: 165px;">Father's Name <p-sortIcon field="ftname"></p-sortIcon></th>
              <th pSortableColumn="mtname" style="width: 165px;">Mother's Name<p-sortIcon field="mtname"></p-sortIcon></th>
              <th clas="smallCol" pSortableColumn="gender" >Gender<p-sortIcon field="gender"></p-sortIcon></th>
              <th pSortableColumn="submission_status" style="width: 165px;">Registration<br>Status <p-sortIcon field="submission_status"></p-sortIcon></th>
              <th clas="smallCol" pSortableColumn="pmt_status" >Payment Status <p-sortIcon field="pmt_status"></p-sortIcon></th>
              <th pSortableColumn="pmt_ID" style="width: 165px;">Payment ID <p-sortIcon field="pmt_ID"></p-sortIcon></th>             
          </tr>
          <tr>             
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'username', 'startsWith')" placeholder="User Name" class="p-column-filter">
              </span>   
            </th>
            <th *ngIf="streamcourseShow == true">
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'stream', 'startsWith')" placeholder="Stream" class="p-column-filter">
              </span>   
            </th>
            <th *ngIf="streamcourseShow == true">
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'course', 'startsWith')" placeholder="Course" class="p-column-filter">
              </span>   
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'rollno', 'startsWith')" placeholder="Roll no" class="p-column-filter">
              </span>
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'division', 'startsWith')" placeholder="Division" class="p-column-filter">
              </span>
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'stname', 'startsWith')" placeholder="Student Name" class="p-column-filter">
              </span>
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'ftname', 'startsWith')" placeholder="Father's name" class="p-column-filter">
              </span>
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'mtname', 'startsWith')" placeholder="Mother's Name" class="p-column-filter">
              </span>
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'gender', 'startsWith')" placeholder="Gender" class="p-column-filter">
               </span> 
            </th>              
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'submission_status', 'startsWith')" placeholder="Submission Status" class="p-column-filter">
              </span>
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'pmt_status', 'startsWith')" placeholder="Payment Status" class="p-column-filter">
              </span>
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getColEventValue($event), 'pmt_ID', 'startsWith')" placeholder="Payment ID" class="p-column-filter">
              </span>
            </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-schoolCandidate >
          <tr>             
              <td clas="smallCol2"> 
                <span class="p-column-title">User Name</span>
                {{schoolCandidate.username}}
            </td>
            <td clas="smallCol2" *ngIf="streamcourseShow == true"> 
              <span class="p-column-title">Stream</span>
              {{schoolCandidate.stream}}
          </td>
          <td clas="smallCol2" *ngIf="streamcourseShow == true"> 
            <span class="p-column-title">Course</span>
            {{schoolCandidate.course}}
        </td>
              <td clas="smallCol2">
                  <span class="p-column-title">Roll No</span>
                  {{schoolCandidate.rollno}}
              </td>                
                <td clas="smallCol2">
                  <span class="p-column-title">Division</span>
                  {{schoolCandidate.division}}
              </td>
              <td>
                  <span class="p-column-title">Student Name</span>
                  {{schoolCandidate.stname}}
      
              </td>
              <td>
                <span class="p-column-title">Student First Name</span>
                {{schoolCandidate.ftname}}
      
            </td>
              <td>
                  <span class="p-column-title">Mother's Name</span>
                  {{schoolCandidate.mtname}}
                 
              </td>
              <td clas="smallCol2">
                <span class="p-column-title">Gender</span>
                {{schoolCandidate.gender}}
                
            </td>
            <td>
              <span class="p-column-title">Submission Status</span>
              {{schoolCandidate.submission_status}}
            </td>
            <td clas="smallCol2">
              <span class="p-column-title">Payment Status</span>
              {{schoolCandidate.pmt_status}}
            </td>
            <td>
              <span class="p-column-title">Payment ID</span>
              {{schoolCandidate.pmt_ID}}
            </td>
          </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
          <tr>
              <td colspan="10">No Students found.</td>
          </tr>
      </ng-template>
    </p-table>
     </div>
   
      
    </div>
</div>