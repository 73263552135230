<div class="container login-container bg-light mb-3">
    <br>
    <div class="card border-dark mb-3">
        <h5 class="card-header"><i class="bi bi-file-earmark-check-fill"></i>&nbsp;Reset Password</h5>
        <div class="card-body text-dark ">
            <!-- <figcaption class="blockquote" style="color: #0062cc;">
                <cite title="Source Title"><b>- Personal Information</b></cite>
             </figcaption> <hr> -->
             <form role="form" [formGroup]= "candResetForm">
                 <div  class="justify-content-md-center">
                
                    <!-- <div class="form-group row mb-3">
                        
                        <label for="staticname" class="col-sm-2 col-form-label">Candidate Username</label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="username" class="form-control" id="staticName" [ngClass]="{ 'is-invalid': submitted && personal.username.errors }">
                          <div *ngIf="submitted && personal.username.errors" class="invalid-feedback">
                            <div *ngIf="personal.username.errors.required">
                              Username Is Required.
                            </div>
                          </div>
                          
                        </div>
                      </div> -->
                      <div class="form-group row mb-3">
                        <label for="staticfathername" class="col-sm-2 col-form-label">New Password</label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="newpass" class="form-control" id="inputboard_user_fname" [ngClass]="{ 'is-invalid': submitted && personal.newpass.errors }">
                           <div *ngIf="submitted && personal.newpass.errors" class="invalid-feedback">
                             <div *ngIf="personal.newpass.errors.required">
                             New Password Required.
                             </div>
                           </div>
                       
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticmothername" class="col-sm-2 col-form-label">Confirm Password</label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="confpass" class="form-control" id="inputusername"  [ngClass]="{ 'is-invalid': submitted && personal.confpass.errors }">
                           <div *ngIf="submitted && personal.confpass.errors" class="invalid-feedback">
                             <div *ngIf="personal.confpass.errors.required">
                               Confirm Password Is Required.
                             </div>
                           </div>
                 
                        </div>
                      </div>
                      
                      <div class="form-group row mb-3">                       
                          <!-- <input type="button" class="btnSubmit btn btn-success"  value="Save Changes" (click)="savedata()" /> &nbsp; -->
                          <input type="button" class="btnSubmit btn btn-primary"  value="Submit" (click)="onsubmit()" /> 
                          <!-- (click)="onstudentprofilesubmit(studentprofileform.value)" -->
                       </div>
                      
                    </div>
                  
               
              </form>
              
        </div>
      </div>
      <!-- <div class="row mb-3 justify-content-md-center">
        <div class="alert alert-dark col-md-8 text-center" role="alert">
          Note: <span class="alert-link">Default Password</span> for every newly created user is <span class="alert-link">123456</span>.
        </div>
      </div> -->
</div>