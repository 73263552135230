
<div class="container">
    <div class="row">
    </div>
    <div class="row clsUpdateStatus mt2">     
      <div class="col-sm-3">
        <label class="">Select Enrollment Year</label>
        <select tabindex="1" class="form-select p-2 text-center " name="yearwise" class="form-control"
              (change)="updateStdList($event)"  aria-label="Default select example">
            <option selected>Select</option>
            <option value="2019-2020">2019-2020</option>
            <option value="2020-2021">2020-2021</option> 
            <option value="2021-2022">2021-2022</option> 
            <option value="2022-2023">2022-2023</option> 
          </select>
      </div>
      <div class="col-sm-3">
        <label class="">Select Class</label>
        <select class="form-select p-2 text-center " name="clswise" class="form-control" 
          (change)="updateListCat($event)" aria-label="Default select example">
          <option *ngFor="let item of dropdownList">{{item}}</option>              
        </select>
      </div>
      <div class="col-sm-3 ">
        <label class="">Select Report</label>
        <select class="form-select p-2 text-center "  name="report" class="form-control"  
         (change)="checkCat($event)" aria-label="Default select example">
            <option *ngFor="let item of dropdownListCat">{{item}}</option>            
          </select>
      </div>
       <div class="col-sm-1" style="margin-top: 10px;" > 
           <!-- <label class=""></label>
         <button type="button" class="btn btn-primary" [disabled]="btnUpdate" >Submit</button> -->
      </div>
      <!-- <div class="col-sm-1" ></div> -->
     <div class="col-sm-2 "style="margin-top: 2.1rem;">
        <label class=""></label>
      <button type="button" class="btn btn-primary" [disabled]="btnUpdate" (click)="exportexcel()">Export to Excel</button>
     </div>
    </div>  
  </div>
  <!-- table  -->
  <div class="container mt-3 bg-light tableBody">
    <div class="row mb-3 justify-content-md-center">
      <div class="col-md-12">
        <div class="card">
            <h5 class="card-header cardheader"><i class="bi bi-clipboard-data"></i>&nbsp;&nbsp;{{rptCat}} Report </h5>
            <div class="card-body">
              
                <div class="hsscdiv" >
                    <table  class="table  table-bordered" id="excel-table">
                        <thead class="headerRow">
                            <tr>
                              <th class="smallClm">Sr. No.</th>
                              <th class="smallClm2">{{rptCatClmTitle}}</th>
                              <th class="smallClm">No. Of Registred Students</th>                              
                            </tr>
                          </thead>
                          <tbody class="tableRow">
                            <tr *ngFor="let item of allData; let i = index ">
                              <td class="smallClm">{{i+1}}</td>
                              <td class="smallClm2">{{item.cat_name}}</td>
                              <td class="smallClm">{{item.count}}</td>                             
                            </tr>
                          </tbody>
                        </table>               
                </div> 
           </div>
          </div>
    </div>   
    </div>
  </div>
  