<section class=" m-5 py-5 s b p-3 ">
    <div class="row ">
      <div class="col-lg-6 mx-auto f  ">
    	 <form class="g-3 justify-content-center  my-3 from-inline ">
            <div class="row ">
                <div class="col-md-6">
                    
                      <label class=""><b>Select Enrollment Year:</b></label>
                      </div>
                    <div class="col-md-6">
                        <select class="form-select p-2 text-center " aria-label="Default select example">
                            <option selected>--Select--</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option> 
                            <option value="2020">2021</option> 
                            <option value="2020">2022</option> 
                          </select>
                        
                    </div>
                     
                    
                 </div>
                 </form>
                 </div>
              </div>
              <div class="row ">
                <div class="col-lg-6 mx-auto f ">
                 <form class=" g-3 justify-content-center my-3 ">
                    <div class="row ">
                        <div class="col-md-6">
                        <label class=""><b>Select Class:</b></label>
                        </div>
                        <div class="col-md-6">
                        <select class="form-select p-2 text-center " id="cls" aria-label="Default select example">
                          <option selected>--Select--</option>
                          <option value="9th">9th</option>
                          <option value="10th">10th</option>  
                        </select>
                        </div>
                    </div>
                    
                 </form>
                </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 mx-auto f ">
                 <form class="g-3 justify-content-center my-3 ">
                        <div class="row ">
                            <div class="col-md-6">
                        <label class=""><b>Select Report:</b></label>
                        </div>
                        <div class="col-md-6">
                        <select class="form-select p-2 text-center "  id="report" aria-label="Default select example">
                          <option selected>--Select--</option>
                          <option value="schoolwise">School wise</option>
                          <option value="talukawise">Taluka wise</option>  
                          <option value="districtwise">District wise</option>
                          <option value="Castewise">Caste wise</option>
                          <option value="genderwise">Gender wise</option>
                          <option value="Managementtype">Management type</option>
                        </select>
                    </div>
                    </div>
                 </form>
                 </div>
                 </div>
                 <div class="mr-3 text-right">
                    <button type="submit" class="btn btn-primary   p-2 px-4 "  onclick="GetSelectedText()">Submit</button>
                </div> 

                <div class="col-lg-12   mx-auto  s  my-5  display" id="example"  style="width:100%" >
                  <h2 class="text-center bc rh text-white  mb-3">(<span id="result"></span>) Report for Class (<span id="std"></span>)  for the year()</h2>
                           
                    <table class="table table-striped table-bordered table-responsive" id="tableID" style="width:100%;" >
                      <thead >
                        <tr>
                          <th>Enrollment code</th>
                          <th>Seat No.</th>
                          <th>Examination</th>
                          <th>Type</th>
                          <th>First Name</th>
                          <th>Middle Name</th>
                          <th>Last Name</th>
                          <th>Gender</th>
                          <th>Stream</th>
                          <th>Course</th>
                          <th>Division</th>
                          <th>Roll No</th>
                        </tr>
                        <tr>
                          <td> <input id="myInp"  type="text" placeholder="Enrollment Code" class="i " ></td>
                          <td> <input id="myInp1"  type="text" placeholder="Seat No." class=" i"></td>
                          <td> <input id="myInp2"  type="text" placeholder="Examination" class="i "></td>
                          <td> <input id="myInp3"  type="text" placeholder="Type" class="i"></td>
                          <td> <input id="myInp4"  type="text" placeholder="First Name" class="i "></td>
                          <td> <input id="myInp5"  type="text" placeholder="Middle Name" class="i"></td>
                          <td> <input id="myInp6"  type="text" placeholder="Last Name" class="i "></td>
                          <td> <input id="myInp7"  type="text" placeholder="Gender" class="i"></td>
                          <td> <input id="myInp8"  type="text" placeholder="Stream" class="i"></td>
                          <td> <input id="myInp9"  type="text" placeholder="Course" class="i"></td>
                          <td> <input id="myInp10"  type="text" placeholder="Division" class="i"></td>
                          <td> <input id="myInp11"  type="text" placeholder="Roll No" class="i"></td>
                       </tr>
                      </thead>
                      <tbody id="">
                         <tr>
                          <td class="ml" >202100073</td>
                          <td class="">30691</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>SOHAM</td>
                          <td>NITIN</td>
                          <td >POLLE</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td class="">34</td>
                        </tr>
                        
                        <tr>
                          <td>202100072</td>
                          <td>30690</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>SHUBHAM</td>
                          <td>YESHWANT</td>
                          <td>MANDREKAR</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>33</td>
                        </tr>
                        <tr>
                          <td>202100071</td>
                          <td>30688</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>SELWYN</td>
                          <td></td>
                          <td>D'SOUZA</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>32</td>
                        </tr>
                        <tr>
                          <td>202100070</td>
                          <td>30687</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>SARTHAK</td>
                          <td>NILESH</td>
                          <td>SHIRODKAR</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>202100069</td>
                          <td>30685</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>SAIRAJ</td>
                          <td>GAJANAN</td>
                          <td>DHAVJEKAR</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>30</td>
                        </tr>
                        <tr>
                          <td>202100068</td>
                          <td>30663</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>ALISTER</td>
                          <td>GAIL</td>
                          <td>PONTES</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>202100067</td>
                          <td>30732</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>SAICHH</td>
                          <td>SHAMSUNDAR</td>
                          <td>RAO</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>29</td>
                        </tr>
                        <tr>
                          <td>202100066</td>
                          <td>30731</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>RUSSEL</td>
                          <td>BENEDICT</td>
                          <td>DIAS</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>28</td>
                        </tr>
                        <tr>
                          <td>202100065</td>
                          <td>30725</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>RATIK</td>
                          <td>PRADEEP</td>
                          <td>NARSE</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>27</td>
                        </tr>
                        <tr>
                          <td>202100064</td>
                          <td>30681</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>PAVAN</td>
                          <td>ASHOK</td>
                          <td>DEVSEKAR</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>26</td>
                        </tr>
                        <tr>
                          <td>202100063</td>
                          <td>30679</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>NOAH</td>
                          <td>EMMANUEL </td>
                          <td>ROCHA</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>25</td>
                        </tr>
                        <tr>
                          <td>202100062</td>
                          <td>30678</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>NESTEL</td>
                          <td>JOAQUIM</td>
                          <td>FERNANDES</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>24</td>
                        </tr>
                        <tr>
                          <td>202100061</td>
                          <td>30677</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>NATHEN</td>
                          <td></td>
                          <td>COELHO</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>202100060</td>
                          <td>30719</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>MYRON</td>
                          <td>CIPRIANO</td>
                          <td>MONIZ</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>22</td>
                        </tr>
                        <tr>
                          <td>202100059</td>
                          <td>30718</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>MOHIT</td>
                          <td></td>
                          <td>SINGH</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>21</td>
                        </tr>
                        <tr>
                          <td>202100058</td>
                          <td>30676</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>MANJUNATH</td>
                          <td>SATISH</td>
                          <td>ICHANAL</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>20</td>
                        </tr>
                        <tr>
                          <td>202100057</td>
                          <td>30696</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>ABHINAV</td>
                          <td>DARSHAN</td>
                          <td>VAIGANKAR</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>202100056</td>
                          <td>30674</td>
                          <td>March 2021</td>
                          <td>Fresher</td>
                          <td>KUNAL</td>
                          <td>PRAKASH</td>
                          <td>NAIK</td>
                          <td>Male</td>
                          <td>S.S.C</td>
                          <td>-</td>
                          <td>B</td>
                          <td>19</td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
</section>
