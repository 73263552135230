<div class="container login-container bg-light mb-3">
    <br>
    <div class="row mb-3 justify-content-md-center">
         
        <div class="col-md-12">
            <div class="card">
                <h5 class="card-header cardheader"><i class="bi bi-border-width"></i> View Board Users</h5>
                <div class="card-body">
                  
                    <div class="hsscdiv" >
                        <table  class="table  table-bordered">
                            <thead class="headerRow">
                                <tr>
                                  <th class="smallClm">Employee ID</th>
                                  <th>Board User Name</th>
                                  <th class="smallClm">Status Of Users </th>
                                  <th class="smallClm">Update Status</th>
                                  <th class="smallClm1">Action</th>
                                </tr>
                              </thead>
                              <tbody class="tableRow">
                                <tr *ngFor="let user of allBoardUsers; let i = index ">
                                  <td class="smallClm">{{user.employeeid}}</td>
                                  <td>{{user.fullname}}</td>
                                  <td class="smallClm2"><span class="badge" [ngClass]="{ 
                                    'badge-success': user.status === 'active',
                                    'badge-danger': user.status === 'deactive'}" >{{user.status}}</span></td>
                                  <td class="smallClm">
                                    <div class="form-check form-switch" >
                                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                      [checked]="user.status=='active'?active:deactive"
                                        
                                      (change)="changeUserStatus($event.target)"
                                      [value]="user.id">
                                      <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                    </div>
                                  </td>                                  
                                  <td class="smallClm1">
                                    <a aria-current="page" [routerLink]="['/editboarduser',user.employeeid]"><button class="btn btn-outline-primary btn-sm btnEdit"><i class="bi bi-pencil-square tblbtn"></i></button></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                   
                    </div>
                    
                    
                </div>
              </div>
        </div>
       
    </div>
  
    <div class="row mb-3"></div>
</div>